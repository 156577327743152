
	import { Vue, Component } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import BlogBannerCard from '@/components/banners/BlogBannerCard.vue';

	@Component({
		components: { BlogBannerCard, BodyText, HeadingText },
	})
	export default class BlogBanner extends Vue {}
