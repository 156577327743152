
	import { Vue, Component } from 'vue-property-decorator';
	import ImagePreviewModal from '@pixcap/ui-core/components/common/ImagePreviewModal.vue';
	import { mapState } from 'vuex';
	import { IPreviewerState, NAMESPACE as PREVIEW_NAMESPACE } from '@/models/store/preview.interface';
	import { mutationsWrapper as PreviewMutations } from '@/store/preview/wrapper';

	@Component({
		name: 'DownloadPreviewModal',
		components: { ImagePreviewModal },
		computed: {
			...mapState(PREVIEW_NAMESPACE, {
				previewImage: (state: IPreviewerState) => state.previewImage,
				previewImageTitle: (state: IPreviewerState) => state.previewImageTitle,
				previewImagePurpose: (state: IPreviewerState) => state.previewImagePurpose,
			}),
		},
	})
	export default class DownloadPreviewModal extends Vue {
		previewImage: IPreviewerState['previewImage'];
		previewImageTitle: IPreviewerState['previewImageTitle'];
		previewImagePurpose: IPreviewerState['previewImagePurpose'];

		handleCloseModal() {
			PreviewMutations.setShowImagePreviewImages(this.$store, undefined);
		}
	}
